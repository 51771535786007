






























import { Component, Vue, Prop } from "vue-property-decorator"
import OcularAvatar from "~/components/OcularAvatar.vue"
import OcularRatingAdvise from "~/components/OcularRating/OcularRatingAdvise.vue"
import Agency from "~/models/Agency"

@Component({
  components: {
    OcularAvatar,
    OcularRatingAdvise,
  },
})
export default class RatingByHomecatchers extends Vue {
  @Prop({ required: true }) agency!: Agency
  @Prop({ default: 2 }) elevation!: number
  @Prop({ default: false }) grayBackgroundItem!: boolean | ""
}
